/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button, Container, Layout } from '../components';
import { navigate } from 'gatsby';
import { loginWithToken } from '../utils/auth';
import { silentAuth } from '../state/session';
import * as api from '../utils/api';
import * as analytics from '../utils/analytics';

const RegistrationSuccessPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const headerText = failed
    ? translate('register.successHeaderFailed')
    : translate('register.successHeader');
  const text = failed
    ? translate('register.successTextFailed')
    : translate('register.successText');

  const cont = async () => {
    if (api.getToken()) {
      const { user, error } = await loginWithToken(api.getToken());
      if (user) {
        setFailed(false);
        dispatch(silentAuth());
        navigate('/');
      }
      if (error) {
        console.error('Registration login failed', error);
        setFailed(true);
      }
    } else if (localStorage.getItem('userId') !== null) {
      try {
        await api.checkEmailValidation(localStorage.getItem('userId'));
        setSuccessMessage(translate('register.message.emailValidationSuccess'));
        localStorage.removeItem('userId');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        console.error('Check of email validation failed', error);
        setFailed(true);
      }
    } else {
      setFailed(true);
    }
  };

  return (
    <Layout
      title={headerText}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container sx={{ maxWidth: 1024, py: [3, 4] }}>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <Styled.h1>{headerText}</Styled.h1>
        <Styled.p>{text}</Styled.p>
        <Button
          onClick={e => {
            e.preventDefault();
            cont();
          }}
        >
          {translate('register.continueButton')}
        </Button>
      </Container>
    </Layout>
  );
};

export default RegistrationSuccessPage;
